<template>
  <div>
    <el-dialog :title="text+'专题讲解'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="100px"
               :rules="rules">

        <el-row>
          <el-col :span="24">
            <el-form-item label="模块:"
                          prop="target_type">
              <el-select v-model="form.target_type"
                         :disabled="text == '上传'"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in targetTypeList"
                           :key="item.id"
                           :label="item.label"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="科目:"
                          prop="subject_id">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         @change="subjectChangeGetSelects"
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="年级:"
                          prop="grade_id">
              <el-select v-model="form.grade_id"
                         multiple
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="知识点:">
              <div class="zhishidian">
                <el-cascader v-model="form.knowledge_id"
                             style="width:100%"
                             :options="selects.knowledge"
                             :props="knowledgeProps"></el-cascader>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="教材:">
              <el-select v-model="form.textbook_id"
                         style="width:100%"
                         clearable
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in textbookList"
                           :key="item.textbook_id"
                           :label="item.textbook_name"
                           :value="item.textbook_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="章节:">
              <div class="zhishidian">
                <el-cascader v-model="form.chapter_id"
                             style="width:100%"
                             :options="selects.chapter"
                             :props="chapterProps"></el-cascader>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="题型:">
              <el-select v-model="form.question_type_id"
                         multiple
                         filterable
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in selects.questionType"
                           :key="item.question_type_id"
                           :label="item.question_type_name"
                           :value="item.question_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="text == '上传'">
          <el-col :span="24">
            <el-form-item label="选择文件">
              <div class="addFile"
                   v-if="fileList.length == 0"
                   @click="upload">
                <i class="el-icon-plus"></i>
              </div>
              <div v-else>
                <el-tag closable
                        v-for="(item,index) in fileList"
                        :key="index"
                        @close="clearFile"
                        type="priamry">
                  {{item.name}}
                </el-tag>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="文件名称"
                          prop="filename">
              <el-input clearable
                        v-model="form.filename"
                        placeholder="请输入文件名称" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="createSource">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelectsBySubject, upLoadSourceApi, editSourceApi } from '@/api/uploadResource.js'
import { getUserSubject } from '@/api/subject.js'
import { getGradeBysubject } from '@/api/grade.js'
import { getTextBookBySubject } from '@/api/textBook.js'
export default {
  data () {
    return {
      show: false,
      form: {},
      text: '上传',
      rules: {
        subject_id: [
          { required: true, message: '请选择科目', trigger: 'change' }
        ],
        target_type: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        filename: [
          { required: true, message: '请输入文件名称', trigger: 'change' }
        ]
      },
      textbookList: [],
      fileList: [],
      subjectList: [],
      gradeList: [],
      selects: {
        knowledge: [],
        chapter: [],
        questionType: [],
      },
      knowledgeProps: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
      },
      chapterProps: {
        multiple: true,
        children: 'child',
        value: 'chapter_id',
        label: 'chapter_desc',
      },
      dialogVisible: false,
      targetTypeList: [
        { id: 1, label: '专题讲解' },
        { id: 2, label: '知识点讲解' },
      ],
      flatKnowledge: {},
      flatChapter: {}
    }
  },

  methods: {
    async getBook () {
      this.form.textbook_id = ''
      this.textbookList = []
      if (!this.form.subject_id) return
      let params = {
        subject_id: this.form.subject_id
      }
      const { data } = await getTextBookBySubject(params)
      this.textbookList = data
    },
    upload () {
      this.fileList = []
      this.$get_file({ fileType: 'video/*' }).then(res => {
        this.fileList.push(res)
        this.form.filename = res.name.substring(0, res.name.indexOf("."))
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    resetForm () {
      this.form.grade_id = []
      this.form.knowledge_id = []
      this.form.chapter_id = []
      this.form.question_type_id = []
      this.form.textbook_id = ''
      this.selects = {
        knowledge: [],
        textbook: [],
        chapter: [],
        questionType: [],
      }
    },
    async subjectChangeGetSelects () {
      this.getBook()
      this.resetForm()
      if (!this.form.subject_id) {
        return
      }
      this.getGradeList()
      // 获取下拉框参数
      this.getSelects()
    },
    async getList () {
      this.getSubjectList()
      if (this.form.subject_id) {
        let params = {
          subject_id: this.form.subject_id
        }
        const { data } = await getTextBookBySubject(params)
        this.textbookList = data
      }
    },
    async getSubjectList () {
      const { data } = await getUserSubject()
      this.subjectList = data.list
    },
    // 从父组件调用此方法 传参为2 不需要清除年级
    async getGradeList (num = 1) {
      if (num == 1) {
        this.form.grade_id = []
      }
      const { data } = await getGradeBysubject({ subject_id: this.form.subject_id })
      this.gradeList = Object.values(data)
    },
    clearFile () {
      this.fileList = []
    },
    createSource () {
      this.$refs.formRef.validate(async val => {
        if (!val) return
        if (this.text != '上传') {
          return this.edit()
        } else if (!this.fileList.length) {
          return this.$message.warning('请选择视频')
        }

        let formData = new FormData();
        let form = JSON.parse(JSON.stringify(this.form))
        form.grade_id = form.grade_id.join(',')
        form.question_type_id = form.question_type_id.join(',')

        form.knowledge_id = [...new Set(form.knowledge_id.join(',').split(','))].join(',')
        form.chapter_id = [...new Set(form.chapter_id.join(',').split(','))].join(',')

        for (const key in form) {
          formData.append(key, form[key])
        }
        formData.append('sign', this.$getMd5Form(form))

        formData.append('video', this.fileList[0])
        await upLoadSourceApi(formData)
        this.$notify({
          title: '提示',
          message: '上传资源成功!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.search()
      })
    },
    async edit () {
      let form = JSON.parse(JSON.stringify(this.form))
      form.grade_id = form.grade_id.join(',')
      form.question_type_id = form.question_type_id.join(',')
      form.knowledge_id = [...new Set(form.knowledge_id.join(',').split(','))].join(',')
      form.chapter_id = [...new Set(form.chapter_id.join(',').split(','))].join(',')
      await editSourceApi(form)
      this.$notify({
        title: '提示',
        message: '成功!',
        type: 'success'
      });
      this.handleClose()
      this.$parent.search()
    },
    async getSelects () {
      let params = {
        subject_id: this.form.subject_id
      }
      const { data } = await getSelectsBySubject(params)
      this.selects = data
    },
    async parentComponentEvent (form) {
      const { data } = await getSelectsBySubject({ subject_id: this.form.subject_id })
      this.selects = data

      this.flatKnowledge = {}
      // 查找知识点完整路径代码块 开始
      var knowledgeList = this.flatList(JSON.parse(JSON.stringify(this.selects.knowledge)))
      knowledgeList.forEach(item => {
        this.flatKnowledge[item.knowledge_id] = item
      });
      var knoIds = []
      // 组建完整的知识点路径
      form.knowledge_id.forEach(item => {
        var obj = this.flatKnowledge[item]
        if (obj.knowledge_parent_id && this.flatKnowledge[obj.knowledge_parent_id].child) {
          knoIds.push(this.searchParentKnowledge(obj))
        } else {
          knoIds.push([item])
        }
      })
      form.knowledge_id = knoIds
      // 结束


      // 查找章节完整路径代码块  开始
      var chapterList = this.flatList(JSON.parse(JSON.stringify(this.selects.chapter)))
      chapterList.forEach(item => {
        this.flatChapter[item.chapter_id] = item
      });
      var chaIds = []
      // 组建完整的章节路径
      form.chapter_id.forEach(item => {
        var obj = this.flatChapter[item]
        if (obj.chapter_parent_id && this.flatChapter[obj.chapter_parent_id] && this.flatChapter[obj.chapter_parent_id].child) {
          chaIds.push(this.searchParentChapter(obj))
        } else {
          chaIds.push([item])
        }
      })
      form.chapter_id = chaIds

      // form.chapter_id = this.buildChapter(JSON.parse(JSON.stringify(this.selects.chapter)), form.chapter_id)
    },
    flatList (tree) {
      var allList = []
      for (let index = 0; index < tree.length; index++) {
        allList.push(tree[index])
        if (tree[index]['child'] && tree[index]['child'].length) {
          allList = [...allList, ...this.flatList(tree[index]['child'])]
        }
      }
      return allList;
    },
    searchParentKnowledge (obj) {
      var arr = []
      // 查找父级 往数组前插入
      arr.unshift(obj.knowledge_id)
      if (obj.knowledge_parent_id) {
        arr = [...this.searchParentKnowledge(this.flatKnowledge[obj.knowledge_parent_id]), ...arr]
      }
      return arr
    },
    searchParentChapter (obj) {
      var arr = []
      // 查找父级 往数组前插入
      arr.unshift(obj.chapter_id)
      if (obj.chapter_parent_id && this.flatChapter[obj.chapter_parent_id]) {
        arr = [...this.searchParentChapter(this.flatChapter[obj.chapter_parent_id]), ...arr]
      }
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .dialog-footer {
  text-align: center;
}
.addFile {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  text-align: center;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
}

::v-deep .el-dialog {
  margin-top: 10vh !important;
}
</style>