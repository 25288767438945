import { instance } from '@/utils/http.js'

export const getSelectsBySubject = function (params) {
  return instance({
    url: '/api/v1/resource/select',
    method: 'get',
    params
  })
}

// 上传资源
export const upLoadSourceApi = function (data) {
  return instance({
    url: '/api/v1/resource/create',
    method: 'post',
    Headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}


// 更新资源
export const editSourceApi = function (data) {
  return instance({
    url: '/api/v1/resource/edit',
    method: 'post',
    data
  })
}

// 批量修改资源
export const batchEdit = function (data) {
  return instance({
    url: '/api/v1/resource/batch',
    method: 'post',
    data
  })
}