import { instance } from '@/utils/http.js'

// 获取全部教材
export const getTextBook = function () {
  return instance({
    url: '/api/v1/resource/textbook',
    method: 'get',
  })
}

// 根据科目获取教材
export const getTextBookBySubject = function (params) {
  return instance({
    url: '/api/v1/public/subject_textbook',
    method: 'get',
    params
  })
}


